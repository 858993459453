// Entry point for the build script in your package.json

// Responsive Embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

// Window animation
import WindowAnimation from "./lib/WindowAnimation";
WindowAnimation.start();

// Smooth scroll
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

// Stimulus
import { Application } from "stimulus";
import HomeController from "./controllers/HomeController";
const application = Application.start();
application.register("home", HomeController);

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;
