import React from "react";

type Props = {
  shape: string;
  alt: string;
  url: string;
  index: string | number;
};

export default function ImageMask(props: Props) {
  const { alt, url, index } = props;

  const path = () => {
    const shape = parseInt(props.shape, 10) || 1;
    const paths = [
      "M230.046 380c-84.843-.074-174.414-56.445-203.204-113.573C-17.052 179.333-16.87 82.868 102.2 25.653 137.872 8.513 198.49.091 265.68 0h2.193C422.02.207 609.567 44.064 610 126.94v.576a55.982 55.982 0 0 1-.132 3.553c-5.362 77.862-136.973 44.371-258.658 193.34-32.477 39.757-75.98 55.552-120.828 55.591h-.336",
      "M546.665 171.199c-10.226-33.181-41.635-57.666-76.675-64.292-26.759-5.061-54.096 2.652-77.756-13.504-22.278-15.214-35.867-40.594-57.21-57.217C308.125 15.234 274.038 2.39 239.36.33 176.215-3.42 113.937 25.077 69.625 68.135 29.988 106.65 2.725 158.01.182 211.918-2.36 265.826 21.777 321.6 67.387 353.543 113 385.485 179.773 389.81 226.478 359.34c34.091-22.243 56.043-60.114 93.557-76.601 31.22-13.721 67.322-10.05 101.635-8.066 34.315 1.984 71.78 1.054 98.658-19.384 25.445-19.351 35.512-54.317 26.337-84.091",
      "M579.565 65.137c-32.401-43.816-97.033-77.474-152.92-50.307-31.97 15.54-55.89 41.861-85.273 61.199-29.386 19.338-70.394 31.152-101.242 13.819-23.04-12.947-33.545-38.135-51.196-56.869C130.834-28.694 7.48.426.306 84.674A83.707 83.707 0 0 0 .395 99.94c3.144 31.991 25.206 59.811 54.793 76.28 25.593 14.246 59.084 16.743 77.314 40.77 17.558 23.144 21.886 53.605 37.896 77.855 20.179 30.566 51.18 55.385 87.082 69.457 65.372 25.625 142.607 18.215 206.593-10.186 57.234-25.406 106.459-68.127 130.517-121.977 24.058-53.849 20.609-118.814-15.025-167.002",
      "M581.995 64.642C520.523-23.875 380.268-8.208 293.111 33.08c-50.707 24.02-117.365 62.753-173.4 30.314C78.384 39.468 30.114-2.838 6.254 65.42c-14.794 42.324-.883 96.426 16.202 136.42C69.84 312.756 194.298 380.442 318.676 380c121.291-.437 261.698-61.275 287.43-183.379 9.28-44.037 2.256-94.012-24.11-131.979",
      "M73.501 27.042c-19.917 13.483-35.619 30.717-45.93 48.675-14.526 25.298-20.693 52.734-24.916 80.015-13.647 88.189 23.55 189.895 152.548 217.019 86.53 18.195 180.36-.434 259.53-27.398C531.058 305.737 694.994 182.416 557.97 90.739c-73.664-49.284-192.657-30.69-279.739-57.53C237.431 20.633 199.02-2.533 152.487.226c-30.348 1.8-57.19 12.062-78.986 26.816",
      "M549.891 234.866c-1.052-16.975-8.507-32.857-16.811-47.686-34.637-61.847-86.785-113.704-148.743-147.917-76.188-42.07-182.954-54.914-262.993-15.735C16.206 74.994-51.163 264.1 49.628 350.425c91.149 78.064 200.4-18.43 305.221-59.968 35.232-13.962 69.964-21.715 103.339-14.543 24.084 5.175 50.736 16.099 71.728 3.18 14.452-8.897 21.028-27.254 19.975-44.228",
      "M609.483 217.307a76.958 76.958 0 0 0-3.006-14.25c-9.167-29.32-35.901-51.259-67.768-61.203-27.567-8.603-60.552-4.77-82.84-23.869-21.47-18.397-31.494-46.068-51.671-65.784-25.431-24.85-60.268-42.338-97.806-48.88-68.353-11.915-141.905 9.228-198.582 47.55C57.113 85.151 17.5 134.14 4.445 188.893c-13.057 54.755 2.713 114.834 46.516 153.31 39.827 34.983 108.998 54.54 158.049 18.862 28.06-20.408 46.244-49.409 71.07-72.892 24.826-23.483 62.37-42.073 95.628-31.553 24.839 7.86 39.853 29.464 60.567 43.724 68.187 46.942 182.353-2.98 173.208-83.037",
      "M66.272 27.042C48.314 40.525 34.156 57.759 24.859 75.717 11.762 101.015 6.202 128.45 2.394 155.732-9.91 243.92 23.628 345.627 139.937 372.75c78.018 18.195 162.62-.434 234.003-27.398 104.883-39.616 252.694-162.937 129.147-254.614-66.418-49.284-173.707-30.69-252.224-57.53C214.077 20.633 179.443-2.533 137.49.226c-27.363 1.8-51.566 12.062-71.217 26.816"
    ];
    return paths[shape - 1];
  };

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 610 380"
        preserveAspectRatio="xMinYMin meet">
        <title>{alt}</title>
        <defs>
          <clipPath id={"image-clip-" + index}>
            <path d={path()} />
          </clipPath>
        </defs>
        <image
          clipPath={`url(#image-clip-${index})`}
          xlinkHref={url}
          x="0"
          y="0"
          width="610px"
          height="380px"
        />
      </svg>
    </div>
  );
}
