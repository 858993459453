interface WindowAnimationHandler {
  (): void;
  handleId?: number;
}

interface WindowAnimationOptions {
  resize?: (width: number, height: number) => void;
  scroll?: (offset: number, width: number, height: number) => void;
  handleId?: number;
}

class WindowAnimation {
  handlers: WindowAnimationHandler[];
  handleId: number;
  scrollOffset: number | null;
  wWidth: number | null;
  wHeight: number | null;
  windowAnimations: WindowAnimationOptions[];
  docHeight: number;
  layoutQueued: boolean;

  constructor() {
    this.handlers = [];
    this.windowAnimations = [];
    this.handleId = 0;
    this.scrollOffset = null;
    this.wWidth = null;
    this.wHeight = null;
    this.docHeight = null;
    this.layoutQueued = false;
  }

  start() {
    const loop = () => {
      this.handlers.forEach((fn) => fn());
      requestAnimationFrame(loop);
    };
    this.handleWindowAnimations();

    this.readyHandler(() => {
      this.queueLayout();
    });

    loop();
  }

  readyHandler(fn: () => void) {
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      setTimeout(fn, 1);
    } else {
      document.addEventListener("DOMContentLoaded", fn);
    }
  }

  elementPosition(elem: HTMLElement) {
    let top = 0,
      left = 0;
    if (elem.offsetParent) {
      do {
        top += elem.offsetTop;
        left += elem.offsetLeft;
      } while ((elem = elem.offsetParent as HTMLElement));
    }
    return [left, top];
  }

  registerHandler(callback: WindowAnimationHandler): number {
    this.handleId += 1;
    callback.handleId = this.handleId;
    this.handlers.push(callback);
    return callback.handleId;
  }

  clearHandler(id) {
    this.handlers = this.handlers.filter((fn) => fn.handleId != id);
  }

  register(options: WindowAnimationOptions): number {
    this.handleId += 1;
    options.handleId = this.handleId;
    this.windowAnimations.push(options);

    // Run immediately if possible
    if (this.wWidth !== null && this.wHeight !== null) {
      if (options.resize != undefined) {
        options.resize(this.wWidth, this.wHeight);
      }
      if (this.scrollOffset !== null && options.scroll != undefined) {
        options.scroll(this.scrollOffset, this.wWidth, this.wHeight);
      }
    }

    return options.handleId;
  }

  clear(id) {
    this.windowAnimations = this.windowAnimations.filter(
      (fn) => fn.handleId != id
    );
  }

  handleWindowAnimations() {
    const loop = () => {
      let docHeight = 0;
      if (document.body && document.body.scrollHeight) {
        docHeight = document.body.scrollHeight;
      }
      // Resize
      if (
        this.layoutQueued ||
        this.wWidth != window.innerWidth ||
        this.wHeight != window.innerHeight ||
        this.docHeight != docHeight
      ) {
        this.wWidth = window.innerWidth;
        this.wHeight = window.innerHeight;
        this.docHeight = docHeight;
        this.scrollOffset = null;

        this.windowAnimations.forEach((opts: WindowAnimationOptions) => {
          if (opts.resize !== undefined) {
            opts.resize(this.wWidth, this.wHeight);
          }
        });
        this.layoutQueued = false;
      }

      // Scroll
      if (this.scrollOffset != window.pageYOffset) {
        this.scrollOffset = window.pageYOffset;
        this.windowAnimations.forEach((opts: WindowAnimationOptions) => {
          if (opts.scroll !== undefined) {
            opts.scroll(this.scrollOffset, this.wWidth, this.wHeight);
          }
        });
      }
    };

    this.registerHandler(loop);
    loop();
  }

  queueLayout() {
    this.layoutQueued = true;
  }
}

export default new WindowAnimation();
