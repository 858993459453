import { Controller } from "stimulus";
import WindowAnimation from "../lib/WindowAnimation";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

export default class extends Controller {
  contestBannerTarget: HTMLDivElement;
  hasContestBannerTarget: boolean;
  headerTarget: HTMLElement;
  navlinkTargets: HTMLElement[];
  sectionTargets: HTMLElement[];
  handle: number;
  currentSection: HTMLElement;

  static get targets() {
    return ["contestBanner", "section", "navlink", "header"];
  }

  connect() {
    const sections = this.sectionTargets.map((t) => ({
      element: t,
      top: null
    }));
    let windowHeight = window.innerHeight;
    let contestBannerHeight = 0;

    this.handle = WindowAnimation.register({
      resize: () => {
        for (let i = 0; i < sections.length; i++) {
          const rect = sections[i].element.getBoundingClientRect();
          sections[i].top = rect.top + window.scrollY;
        }
        windowHeight = window.innerHeight;

        if (this.hasContestBannerTarget) {
          contestBannerHeight =
            this.contestBannerTarget.getBoundingClientRect().height;
        }
      },
      scroll: (offset) => {
        const section = sections
          .filter((s) => {
            return offset > s.top - windowHeight / 3;
          })
          .reverse()[0];

        if (section && section.element !== this.currentSection) {
          this.setCurrentSection(section.element);
        }

        if (offset < contestBannerHeight) {
          this.headerTarget.classList.remove("fixed");
        } else {
          this.headerTarget.classList.add("fixed");
        }
      }
    });
    WindowAnimation.readyHandler(() => {
      this.readStateFromLocation();
    });
  }

  navlinkByUrl(url: string) {
    return this.navlinkTargets.filter((t) => t.dataset.url === url)[0];
  }

  readStateFromLocation() {
    const path = this.relativePath();
    const section = this.sectionByUrl(path);
    if (path !== "/" && section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  relativePath() {
    if (typeof document == "undefined") {
      return null;
    } else {
      return document.location.pathname;
    }
  }

  sectionByUrl(url: string) {
    return this.sectionTargets.filter((t) => t.dataset.url === url)[0];
  }

  setCurrentSection(section: HTMLElement) {
    const navlink = this.navlinkByUrl(section.dataset.url);

    this.currentSection = section;

    if (section.dataset.invert == "true") {
      this.headerTarget.classList.add("invert");
    } else {
      this.headerTarget.classList.remove("invert");
    }

    for (let i = 0; i < this.navlinkTargets.length; i++) {
      this.navlinkTargets[i].classList.remove("current");
    }

    if (navlink) {
      navlink.classList.add("current");
    }

    // if (document.location !== section.dataset.url) {
    //   window.history.replaceState({}, "", section.dataset.url);
    //   this.trackEvent();
    // }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "sectionVisible",
      url: section.dataset.url
    });
  }

  disconnect() {
    WindowAnimation.clear(this.handle);
  }

  navigate(evt: Event) {
    evt.preventDefault();
    if (evt.target) {
      const target = evt.target as HTMLElement;
      const url = target.dataset.url;
      const section = this.sectionByUrl(url);
      section.scrollIntoView({ behavior: "smooth" });
      window.history.pushState({}, "", url);
      this.trackEvent();
      this.headerTarget.classList.remove("show");
    }
  }

  trackEvent() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "pageview" });
  }

  toggleNav() {
    this.headerTarget.classList.toggle("show");
  }
}
